//~~~~~~~~~~~~~~ Module Imports ~~~~~~~~~~~~~~//
import { Grid } from '@mui/material';
import Button from '@mui/material/Button/Button';

//~~~~~~~~~~~~~~ File Imports ~~~~~~~~~~~~~~//
import valknut from '../assets/valknut.png'
import '../css/Header.css';
const Header = () => {  
    return (
        <Grid container className='header'>
            <Grid item={true} xs={6} className="leftElements">
                <img src={valknut} />
                <a href='/'>Home</a>
                <a href='/generator'>Make a story</a>
                <a>Use your own story</a>
            </Grid>
            <Grid item={true} xs={6} textAlign={"right"} className="rightElements">
                <a>FAQ</a>
                <a>How to use</a>
                <img src={valknut} />
            </Grid>
        </Grid>
    )
}

export default Header;