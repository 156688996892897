//~~~~~~~~~~~~~~ Module Imports ~~~~~~~~~~~~~~//
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider, responsiveFontSizes} from '@mui/material/styles';

//~~~~~~~~~~~~~~ Page Imports ~~~~~~~~~~~~~~//
import Home from './Home';
import Generator from './Generator';
import Header from './components/Header';

//~~~~~~~~~~~~~~ Other Imports ~~~~~~~~~~~~~~//
import './css/Global.css';


function App() {
	let darkTheme = createTheme({
	palette: {
		mode: 'dark',
	},
	});
	// darkTheme.typography.h3
	darkTheme = responsiveFontSizes(darkTheme);
	return (
		<ThemeProvider theme={darkTheme}>
			<BrowserRouter>
					<Header/>
					<Routes>
						<Route path="/" element={<Home />} />
						<Route path="/generator" element={<Generator />} />
					</Routes>
				</BrowserRouter>
		</ThemeProvider>
	);
}

export default App;
