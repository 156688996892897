//~~~~~~~~~~~~~~ Material UI Imports ~~~~~~~~~~~~~~//
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Autocomplete, Chip, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button/Button';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';
import Crop32SharpIcon from '@mui/icons-material/Crop32Sharp';
import CropSquareSharpIcon from '@mui/icons-material/CropSquareSharp';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';

//~~~~~~~~~~~~~~ Other Imports ~~~~~~~~~~~~~~//
import React, { useEffect, useRef } from 'react'
import bookCover2by2 from './assets/bookCover2:2.png';
import bookCover2by3 from './assets/bookCover2:3.png';
import bookCover3by2 from './assets/bookCover3:2.png';
import background from './assets/generatorBackground.png';
import './css/Generator.css';
import BookComponent from "./components/Book";
import { generateStory, generateImage, getImageKeywords, midjourneyGenerate, checkQueue, splitStory} from './dao';
const FAILED_JOB_CODE = -1;
const IN_PROGRESS_CODE = 0;
const FINISHED_CODE = -2;
const OPENAI_PAGE_LIMIT = 7;


const Generator = () => {

    useEffect(() => {
        document.title = 'AI Storybook | Generator';
    }, []);

    interface GeneratorFields {
        prompt: string;
        creativity: number;
        pages: number;
        imageTags: string[];
        aspectRatio: string;
        useOpenAi: boolean;
        generateCover: boolean;
    }
    interface QueueResponse {
        queuePos: number;
        generationsFinished: number;
        images: any;
    }


    const imageGenOptions = [
        'Real Life',
        'Realistic',
        'HD',
        'Fantasy',
        'Horror',
        'Photo realism',
        '3D',
        '2D',
        'In cartoon style',
        'In disney style',
        'In anime style',
        'In manga style',
        'In black and white',
        'Impressionist style'
    ];
    
    
    
    
    const [loadingMessage, setLoadingMessage] = React.useState<string>('');
    const [tab, setTab] = React.useState<number>(0);
    const [bookAspectRatio, setBookAspectRatio] = React.useState<string>('2:3');
    const [title, setTitle] = React.useState<string>('');
    const [disableButtons, setDisableButtons] = React.useState<boolean>(false);
    const [textPages, setTextPages] = React.useState<any>([]);
    const [pageCover, setPageCover] = React.useState<string>('');
    const [loadingPages, setLoadingPages] = React.useState({ 'totalPages': 0, 'pagesDone': 0 })
    const [newImagePrompt, setNewImagePrompt] = React.useState<string>('');
    const [generatorData, setGeneratorData] = React.useState<GeneratorFields>({
        prompt: "Write me a fantasy story",
        creativity: 0.8,
        pages: 3,
        imageTags: [],
        aspectRatio: "2:2",
        useOpenAi: true,
        generateCover: false
    });

    const [test, setTest] = React.useState<any>('');

    const handleChange = (prop: keyof GeneratorFields) => (event: React.ChangeEvent<HTMLInputElement>) => { setGeneratorData({ ...generatorData, [prop]: event.target.value }); };
    const changeCreativity = (event: Event, newValue: number | number[]) => { setGeneratorData({ ...generatorData, "creativity": newValue as number }); };

    const unsetLoading = () => {
        setDisableButtons(false);
        let loadingBar = document.querySelector('.pageLoadingBar') as HTMLElement | null;
        if (loadingBar !== null)
            loadingBar.style.display = "none";
        return;
    }

    const setLoading = (totalPages: number) => {
        setLoadingPages({ 'totalPages': totalPages, 'pagesDone': 0 });
        setLoadingMessage(`0 out of ${totalPages} images generated`);
        let loadingBar = document.querySelector('.pageLoadingBar') as HTMLElement | null;
        if (loadingBar !== null)
            loadingBar.style.display = "block";
        return;
    }

    const handleSubmit = async () => {
        let loadingBar = document.querySelector('.test') as HTMLElement | null;
        if (loadingBar !== null)
            loadingBar.remove();
        setTextPages([]);
        setTitle('');
        setPageCover('');
        setDisableButtons(true);
        let story: string = await generateStory(generatorData.prompt, generatorData.creativity, generatorData.pages);
        const {title, pages } = splitStory(story);
        setTitle(title);
        setLoading(pages.length);
        if (generatorData.useOpenAi) {
            const pageData = [];
            let pagesDone = 0;
            for (const page of pages) {
                let image: string = await generateImage(page, generatorData.imageTags);
                // If image fails to return becuase of some error, use keywords to generate a new image
                if (image === "") {
                    const imageKeywords: string = await getImageKeywords(page);
                    image = await generateImage(imageKeywords, generatorData.imageTags);
                }
                pagesDone++;
                setLoadingMessage(`${pagesDone} out of ${pages.length} images generated`);
                setLoadingPages({ 'totalPages': pages.length, 'pagesDone': pagesDone })
                // Here pageData length would equate to the last pushed image, so minus 2 to get it to the image before that
                pageData.push(page);
                pageData.push(image);
                // pageData.push(house);
            }
            unsetLoading();
            setTest(<BookComponent  bookAspectRatio={bookAspectRatio} bookCover={pageCover} imageAspectRatio={generatorData.aspectRatio} pages={pageData} title={title}/>)
            setTextPages(pageData);
        }
        else {
            let interval: any = null;
            // If the user wants to generate a cover for the book, then add a prompt for that at the start of the array passed to midjourneyGenerate
            let setId = await midjourneyGenerate(generatorData.generateCover ? [ `Cover for a book with the title "${title}"`].concat(pages) : pages, generatorData.imageTags, generatorData.aspectRatio, generatorData.generateCover, bookAspectRatio);
            if (setId === '') {
                // Throw error here
                return;
            }
            interval = setInterval(async () => {
                let response: QueueResponse = await checkQueue(setId);
                if (response.queuePos === FAILED_JOB_CODE) {
                    unsetLoading();
                    clearInterval(interval);
                    setLoadingMessage('An unexpected error occurred when generating your images');
                }
                else if (response.queuePos === FINISHED_CODE) {
                    const pageData: any = [];
                    for (let i = 0; i < pages.length; i++) {
                        pageData.push(pages[i]);
                        // storyPages is i+1 because the first image is the title page
                        pageData.push(response.images[(generatorData.generateCover) ? i+1 : i].imageUrl);
                    }
                    setTextPages(pageData);
                    setTest(<BookComponent  bookAspectRatio={bookAspectRatio} bookCover={(generatorData.generateCover) ? response.images[0].imageUrl : ''} imageAspectRatio={generatorData.aspectRatio} pages={pageData} title={title}/>)
                    unsetLoading();
                    clearInterval(interval);
                }
                else if (response.queuePos === IN_PROGRESS_CODE) {
                    setLoadingPages({ totalPages: pages.length, pagesDone: response.generationsFinished })
                    setLoadingMessage(`${response.generationsFinished} out of ${pages.length} images generated`);
                }
                else {
                // Else the user is still in the queue 
                setLoadingMessage(`Your queue position is ${response.queuePos}`);
                }
            }, 10000, setId, interval, pages)
        }
        return;
    }


    const regenerateImage = async () => {
        // setDisableButtons(true);
        // let image: string = await generateImage(newImagePrompt, generatorData.imageTags);
        // let newPages = [...pages];
        // newPages[currentPage] = image;
        // setPages(newPages);
        // setDisableButtons(false);
        return;
    }

    return (
        <div className='generator'>
            <img src={background} className='background' alt='Background' />
            <Grid container justifyContent={"space-around"}>
                {/* Grid for the story parameters */}
                <Grid item={true} xs={5} className='form ' container justifyContent="space-between" alignContent={"flex-start"} rowSpacing={2} role="tabpanel">
                    {/* <Grid xs={12} item={true}>
                        <Tabs value={tab} aria-label='Tab for story parameters'>
                            <Tab label='Story Parameters' />
                        </Tabs>
                    </Grid> */}
                    <h1 style={{marginTop:'25px'}}>Choose story parameters</h1>
                    {/* Grid for the pages */}
                    <Grid item={true} xs={5.5}>
                        <TextField 
                            label="Pages" 
                            variant="outlined" 
                            onChange={handleChange("pages")} 
                            value={generatorData.pages} 
                            type="number" 
                            InputProps={{ 
                                inputProps: { 
                                    max: (generatorData.useOpenAi) ? OPENAI_PAGE_LIMIT : (generatorData.generateCover) ? 4 : 5, 
                                    min: 1 } 
                                }}
                            fullWidth />
                    </Grid>
                    {/* Grid for the creativity slider */}
                    <Grid item={true} xs={5.5} >
                        <p>Creativity</p>
                        <Slider aria-label="Creativity level" defaultValue={0.5} valueLabelDisplay="auto" step={0.1} min={0} max={1} value={generatorData.creativity} onChange={changeCreativity} />
                    </Grid>
                    {/* Grid for the prompt */}
                    <Grid item={true} xs={12} marginBottom="12">
                        <TextField label="Prompt" variant="outlined" fullWidth multiline rows={2} onChange={handleChange("prompt")} value={generatorData.prompt} />
                    </Grid>
                    <Button className='submitButton' fullWidth variant='contained' disabled={disableButtons} onClick={handleSubmit}>Submit</Button>
                </Grid>



                {/* Grid for the image parameters */}
                <Grid item={true} xs={5} position={'relative'} sx={{ 'marginRight': '25px' }} className='form' container justifyContent="space-between" rowSpacing={2} role="tabpanel">
                    {/* <Grid xs={12} item={true}>
                        <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)} aria-label='tabs for swapping between story parameters and image parameters'>
                            <Tab label='Book & Image Parameters' />
                            {currentPage !== 0 && (<Tab label={"Page " + (currentPage + 1) + " Image Parameters"} />)}
                        </Tabs>
                    </Grid> */}
                    {tab === 0 && (
                        <>
                            <Grid item={true} xs={8}>
                                <h1>Choose image parameters</h1>
                            </Grid>
                            <Grid item={true} xs={4}>
                                {/* <p>Image generator</p> */}
                                <Tooltip title={<div>OPENAI generates lower quality images quickly and has a limit of {OPENAI_PAGE_LIMIT} images <br /><br /> Midjourney generates high quality images very slowly and has a limit of 5 images</div>}>
                                    <ToggleButtonGroup 
                                    color="primary" 
                                    value={generatorData.useOpenAi} 
                                    exclusive aria-label="Choose which AI to generate images"
                                    onChange={(event: React.MouseEvent<HTMLElement>, useOpenAi: boolean,) => { 
                                        setGeneratorData({
                                            ...generatorData, 
                                            useOpenAi: useOpenAi, 
                                            aspectRatio: '2:2', 
                                            generateCover: false, 
                                            pages: (!useOpenAi && generatorData.pages >= 5) ? 4 : generatorData.pages
                                        });
                                    }}
                                    disabled={disableButtons}
                                    >
                                        <ToggleButton value={true}> OPENAI </ToggleButton>
                                        <ToggleButton value={false}>MidJourney</ToggleButton>
                                    </ToggleButtonGroup>
                                </Tooltip>
                            </Grid>
                            {/* Grid for the extra tags */}
                            <Grid item={true} xs={12} >
                                {/* This chip input field code was taken from https://codesandbox.io/s/long-morning-oubt4, shout out to Samira Rezayi */}
                                <Autocomplete multiple id="tags-filled" options={imageGenOptions} disablePortal={true} defaultValue={generatorData.imageTags} freeSolo
                                    onChange={(e, values: string[]) => { setGeneratorData({ ...generatorData, "imageTags": values }) }}
                                    renderTags={(
                                        value: any[],
                                        getTagProps: (arg0: { index: any }) => JSX.IntrinsicAttributes) =>
                                        value.map((option: any, index: any) => { return (<Chip key={index} variant="outlined" label={option} {...getTagProps({ index })} />); })
                                    }
                                    renderInput={(params: any) => (<TextField {...params} label="Extra Image Tags" placeholder="Add tags to change how the images generate" />)}
                                    
                                />
                            </Grid>
                            {/* Grid for the image aspect ratio */}
                            <Grid item={true} xs={3}>
                                <p className='ratioText' >Image aspect ratio</p>
                                <ToggleButtonGroup disabled={disableButtons} color="primary" value={generatorData.aspectRatio} exclusive aria-label="Image aspect ratio"
                                    onChange={(event: React.MouseEvent<HTMLElement>, newRatio: string,) => { setGeneratorData({...generatorData, aspectRatio: newRatio}); }}
                                >
                                    <ToggleButton value={'2:3'} disabled={generatorData.useOpenAi}><Crop32SharpIcon sx={{ transform: 'rotate(90deg)' }} /></ToggleButton>
                                    <ToggleButton value={'3:2'} disabled={generatorData.useOpenAi}><Crop32SharpIcon /></ToggleButton>
                                    <ToggleButton value={'2:2'}><CropSquareSharpIcon /></ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            {/* Grid for the book aspect ratio */}
                            <Grid item={true} xs={3}>
                                <p className='ratioText'>Book aspect ratio</p>
                                <ToggleButtonGroup disabled={disableButtons} color="primary" value={bookAspectRatio} exclusive aria-label="Book aspect ratio" onChange={(event: React.MouseEvent<HTMLElement>, newRatio: string,) => { setBookAspectRatio(newRatio); }}>
                                    <ToggleButton value={'2:3'}><Crop32SharpIcon sx={{ transform: 'rotate(90deg)' }} /></ToggleButton>
                                    <ToggleButton value={'3:2'}><Crop32SharpIcon /></ToggleButton>
                                    <ToggleButton value={'2:2'}><CropSquareSharpIcon /></ToggleButton>
                                </ToggleButtonGroup>
                            </Grid>
                            {/* Grid for the generate book cover switch */}
                            <Grid item={true} xs={5} >
                                <FormControlLabel disabled={disableButtons} className='bookCoverSwitch' control={<Switch checked={generatorData.generateCover} disabled={generatorData.useOpenAi} onChange={() => setGeneratorData({...generatorData, generateCover: !generatorData.generateCover, pages: 4})}/>} label="Generate book cover" />
                            </Grid>
                            <Button className='submitButton' fullWidth variant='contained' disabled={disableButtons} onClick={handleSubmit}>Submit</Button>
                        </>
                    )}
                    {tab === 1 && (
                        <>
                            <h1>Choose image parameters</h1>
                            {/* Grid for the prompt */}
                            <Grid item={true} xs={12} marginBottom="12">
                                <TextField label="Prompt" variant="outlined" fullWidth multiline rows={4} onChange={(e) => setNewImagePrompt(e.target.value)} value={newImagePrompt} />
                            </Grid>
                            <Grid item={true} xs={12}>
                                {/* This chip input field code was taken from https://codesandbox.io/s/long-morning-oubt4, shout out to Samira Rezayi */}
                                <Autocomplete multiple id="tags-filled" options={imageGenOptions} disablePortal={true} defaultValue={generatorData.imageTags} freeSolo
                                    onChange={(e, values: string[]) => { setGeneratorData({ ...generatorData, "imageTags": values }) }}
                                    renderTags={(
                                        value: any[],
                                        getTagProps: (arg0: { index: any }) => JSX.IntrinsicAttributes) =>
                                        value.map((option: any, index: any) => { return (<Chip key={index} variant="outlined" label={option} {...getTagProps({ index })} />); })
                                    }
                                    renderInput={(params: any) => (<TextField {...params} label="Extra Image Tags" placeholder="Add tags to change how the images generate" />)}
                                />
                            </Grid>
                            <Button className='submitButton' fullWidth variant='contained' disabled={disableButtons} onClick={regenerateImage}>Recreate Image</Button>
                        </>
                    )}
                </Grid>
                    {/* test does not update upon state change when just providing {test} or {(bookAspectRatio) && test}, so I needed a line for each aspect ratio */}
                    {/* {(bookAspectRatio == '2:3') && test}
                    {(bookAspectRatio == '3:2') && test}
                    {(bookAspectRatio == '2:2') && test} */}
                    {(textPages.length === 0) 
                    ? 
                        <Grid item={true} xs={(bookAspectRatio === '2:3') ? 9 : 12} position={'relative'} container sx={{ 'margin': '15px 25px 0px' }} >
                            {/* <img className='tempBook' src={(bookAspectRatio === '3:2') ? bookCover3by2 : (bookAspectRatio === '2:3') ? bookCover2by3 : bookCover2by2} /> */}
                            <div className='tempBook'>
                                <p>{title}</p>
                                <img className='bookCover' src={(bookAspectRatio === '3:2') ? bookCover3by2 : (bookAspectRatio === '2:3') ? bookCover2by3 : bookCover2by2} />
                                    <div className='pageLoadingBar' >
                                        {(loadingPages.totalPages > 0) 
                                        ? 
                                            <LinearProgress color="secondary" variant="determinate" value={(loadingPages.pagesDone / loadingPages.totalPages) * 100} />
                                        :
                                             null
                                        }
                                        {/* <p>Currently loading: {loadingPages.pagesDone} out of {loadingPages.totalPages} pages done</p> */}
                                        <p>{loadingMessage}</p>
                                    </div>
                            </div>
                        </Grid>   
                    :
                    <>
                        {test}
                    </>
    
                    }
            </Grid>

        </div>
    )
}
export default Generator;
