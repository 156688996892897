// curl https://api.openai.com/v1/completions    -H 'Content-Type: application/json'    -H 'Authorization: Bearer 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'    -d '{   "model": "text-davinci-003",   "prompt": "Tell me a story",   "max_tokens": 100,   "temperature": 0 }'
import axios from "axios";
import { AxiosResponse, AxiosError } from "axios";


const EXPRESS_API = 'https://api.cybensis.cc/api/v1/storyBook';
// const EXPRESS_API = 'http://localhost:5000/api/v1/storyBook';
const WORDS_PER_PAGE = 75;

interface CreateStoryResponse {
    'text': string,
    'index': number,
    'logprobs': any,
    'finish_reason': string
}

const midjourneyGenerate =  async (imagePrompts: string[], imageTags:string[], aspectRatio: string, generateCover: boolean, bookAspectRatio: string) => {
    let newPrompts: string[] = [];
    let i = 0;
    if (generateCover) {
        i = 1;
        newPrompts.push(imagePrompts[0]);
    }
    for (i = i ; i < imagePrompts.length; i++) {
        try {
            const response: AxiosResponse = await axios.post(EXPRESS_API + '/getImageKeywords', {sentence:imagePrompts[i]});
            if (response.data !== undefined) {
                newPrompts.push(response.data)
            }
        } catch (e) { return "";}
    }
    const DATA = {
        imagePrompts:newPrompts,
        imageTags:imageTags,
        aspectRatio: aspectRatio,
        generateCover: generateCover,
        bookAspectRatio: bookAspectRatio
    }
    try {
        const response: AxiosResponse = await axios.post(EXPRESS_API + '/midjourneyGenerate', DATA);
        if (response.data !== undefined) {
            return response.data;
        }
    } catch (e) { return "";}
}

const checkQueue = async (setId: string) => {
    const DATA = {setId: setId}
    interface QueueResponse {
        queuePos: number;
        generationsFinished: number;
        images: any;
    } 
    const queueResponse: QueueResponse = {        
        queuePos: -1,
        generationsFinished: 0,
        images: []}
    try {
        const response: AxiosResponse = await axios.post(EXPRESS_API + '/checkQueue', DATA);
        if (response.data !== undefined) {
            queueResponse.queuePos = response.data.queuePos;
            queueResponse.generationsFinished = response.data.generationsFinished;
            queueResponse.images = response.data.images;
        }
    } catch (e) { 
        console.log(e);
    }
    return queueResponse;
    
}

const generateStory = async (prompt: string, temperature: number, pages: number) => {
    // return `The Dragon's Gift

    // The kingdom of Aedryn was in peril. A dire dragon was ravaging the countryside, and no one could stand against it. Their only hope lay in finding a legendary weapon of the gods.
    
    // But just when all seemed lost, a strange old man came to the castle. He said he had a gift from the dragon itself. He opened his hand to reveal a shining golden sword.
    
    // The king accepted the offering and used the sword to vanquish the dragon. After the battle, the old man revealed himself to be a powerful wizard. He said the sword was his final gift to the kingdom, and it would protect them for generations to come.
    
    // The kingdom of Aedryn was safe once more, thanks to the dragon's gift.`
    
    const DATA = {
        'prompt':prompt,
        'temperature':temperature,
        'pages':pages
    }
    try {
        const response: AxiosResponse = await axios.post(EXPRESS_API + '/generateStory', DATA);
        if (response.data !== undefined) {
            return response.data;
        }
    } catch (e) { return "";}


    // try {
    //     const DATA = {
    //         'model': 'text-davinci-003',
    //         'prompt': `${prompt} in ${pages * WORDS_PER_PAGE} words or less
    //         Title:

    //         Text:
    //         `,
    //         'max_tokens': (pages * 2) * WORDS_PER_PAGE,
    //         'temperature': temperature
    //     }
    //     // return `\n\nOnce upon a time there lived a young boy named Xavier. He lived in a small, quiet village, far away from any kind of adventure or excitement. He was content with his life, but sometimes he wished he could dream of a different world.\n\nOne day, while walking home from school, Xavier stumbled upon an old, dusty bookstore. He hadn't seen this store before, but it seemed to be welcoming him inside. He stepped through the door, and was met with an overwhelming and strange smell. He looked around and saw sh…to the night and when he finally finished the book, he realized that he had been reading for hours. He slowly put the book back on the shelf, and turned to leave.\n\nAs he stepped out of the store, he noticed something strange. The sky was lit up with stars, and the stars were moving. He stepped back in shock, wondering what was going on.\n\nAs Xavier watched, the stars began to swirl and form a pathway in front of him. He knew then that he was being summoned for an adventure. Without a second thought, he`;
    //     // return `

    //     // The night was cold and dark, the stars barely visible in the sky. It was just another night, like any other.
        
    //     // Suddenly, there was an eerie sound coming from the woods. It was as if something was rustling through the trees, and it was getting louder.
        
    //     // The sound grew louder and closer as it approached, until it was right outside the door. It was a figure, shrouded in darkness and fog, slowly creeping closer.
        
    //     // The figure stepped closer`
    //     const response: AxiosResponse = await axios.post(STORY_GEN_HOST, DATA, {headers: HEADERS});
    //     if (response.data !== undefined && response.data.choices != undefined) {
    //         return response.data.choices[0].text;
    //     }
    //     else {
    //         return "";
    //     }
    // }
    // catch (e) { return ""; }
}

const getImageKeywords = async (sentence: string) => {

    // Describe the text below as an image in 10 words or less.
//     const DATA = {
//         'model': 'text-davinci-003',
//         'prompt': `
//         Describe this text as an image in 40 tokens or less

// Text: ${sentence}

// Description:
//         `,
//         'max_tokens': 40,
//         'temperature': 0.1
//     }
//     try {
//         // return ""
//         const response: AxiosResponse = await axios.post(STORY_GEN_HOST, DATA, {headers: HEADERS});
//         if (response.data !== undefined && response.data.choices != undefined) {
//             return response.data.choices[0].text
//         }
//         else {
//             return "";
//         }
//     }
//     catch (e) { return ""; }
    return "";
}


const generateImage = async (imagePrompt: string, imageTags:string[]) => {
    try {
        const response: AxiosResponse = await axios.post(EXPRESS_API + '/getImageKeywords', {sentence:imagePrompt});
        if (response.data !== undefined) {
            imagePrompt = response.data
        }
    } catch (e) { return "";}

    const DATA = {
        'imagePrompt':imagePrompt,
        'imageTags':imageTags,
    }
    try {
        const response: AxiosResponse = await axios.post(EXPRESS_API + '/generateImage', DATA);
        if (response.data !== undefined) {
            return response.data;
        }
    } catch (e) { return "";}
    // let prompt = imagePrompt;
    // imageTags.forEach(tag => {imagePrompt += ". " + tag});
    // const DATA = {
    //     'prompt': imagePrompt,
    //     'n': 1,
    //     'size': '1024x1024'
    // };
    // try {
    //     // return "https://oaidalleapiprodscus.blob.core.windows.net/private/org-0c1UOOacs4m5Lv5BtdhXvVKK/user-2EQxh3V6HmDnmZzHxAVQFzzQ/img-AeeypbaSm3gWW4trK2NW1yui.png?st=2022-12-24T05%3A47%3A05Z&se=2022-12-24T07%3A47%3A05Z&sp=r&sv=2021-08-06&sr=b&rscd=inline&rsct=image/png&skoid=6aaadede-4fb3-4698-a8f6-684d7786b067&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2022-12-23T20%3A41%3A52Z&ske=2022-12-24T20%3A41%3A52Z&sks=b&skv=2021-08-06&sig=fzksmct3GnemMLysrKiVPrVeH52bQ7jK/s1aAsnm4gs%3D";
    //     const response: AxiosResponse = await axios.post(IMAGE_GEN_HOST, DATA, {headers: HEADERS});
    //     if (response.data !== undefined && response.data.data !== undefined)
    //         return response.data.data[0].url;
    //     else
    //         return "";
    // }
    // catch (e) { return ""; }

}
// Describe this text as an image\n\nText: the door and were met with a scene of pure horror. Blood was everywhere, and the walls were covered in dark symbols and messages. In the corner of the room, a figure was crouched, shaking and muttering something in a language none of them had ever heard before. They backed \n\nDescription:

interface SplitStory {
    title: string;
    pages: string[]
}

const splitStory = (story: string): SplitStory => {
    let response: SplitStory = {
        title: '',
        pages: []
    }
    // filter(Boolean) makes sure there are no empty array elements when split
    let sentences = story.split("\n").filter(Boolean);
    // Just check if the length of the first element is less than 75 characters so we know its a title and not a whole ass sentence
    if (sentences[0].length < 75) {
        response.title = sentences[0];
        story = '';
        // Merge story back together without the title
        for (let i = 1; i < sentences.length; i++)
            story += sentences[i] + '\n\n';
    }
    let words = story.split(" ").filter(Boolean);
    let pages: string[] = [];
    let currentPage = 0;
    let pageWords = "";
    // Loop through all the words, all them one by one to a page, then everytime (i mod WORDS_PER_PAGE === 0) then finish the page
    // Start the loop at one so the i % WORDS_PER_PAGE === 0 doesn't get triggered instantly
    for (let i = 1; i <= words.length; i++) {
        if (i % WORDS_PER_PAGE === 0) {
            currentPage++;
            pages.push(pageWords);
            pageWords = "";
        }
        // -1 here because the for loop starts at 1
        pageWords += words[i - 1] + " ";
    }
    // If there are only 10 words on the last page, don't bother making a new page, otherwise create a new one
    if (words.length % WORDS_PER_PAGE <= 10)
        pages[pages.length - 1] += pageWords;
    else
        pages.push(pageWords);
    response.pages = pages;
    return response;
}


export { generateStory, generateImage, getImageKeywords, midjourneyGenerate, checkQueue, splitStory };
