//~~~~~~~~~~~~~~ Module Imports ~~~~~~~~~~~~~~//
import React, { useEffect } from "react";
import { Grid } from '@mui/material';
import Button from '@mui/material/Button/Button';


//~~~~~~~~~~~~~~ File Imports ~~~~~~~~~~~~~~//
import './css/Home.css';
import background from './assets/homeBackground.png'

const Home = () => {
    interface CreateStoryResponse {
		'text':string,
		'index':number,
		'logprobs': any,
		'finish_reason': string
	}
	useEffect(() => {
		document.title = 'AI Storybook | Home';
	  }, []);
    return (
        <div className="home">
				<img src={background} className="background" alt="logo" data-atropos-offset="-5"/>
				<Grid container className="body">
					<Grid item={true} xs={3} />

					<Grid item={true} xs={2.5} >
						<h1>AI Storybook Generator</h1>
						<p>
							Using OPENAI's text completion and image generation AI models, you can now create your own storybook simply by providing a short prompt. The AI will take your prompt and use it to generate a story based on what you've provided, then splitting up the story into pages, each page will have its own unique image that is generated by AI based on the contents of that page. This is still a work in progress so features are limited, but there are many plans for the future.
						</p>
						<Button variant='contained' href='/generator'>Get Started</Button>
					</Grid>
					<Grid item={true} xs={4} />

				</Grid>
				{/* <p>No one shall be subjected to arbitrary arrest, detention or exile. Everyone is entitled in full equality to a fair and public hearing by an independent and impartial tribunal, in the determination of his rights and obligations and of any criminal charge against him. No one shall be subjected to arbitrary interference with his privacy, family, home or correspondence, nor to attacks upon his honour and reputation. Everyone has the right to the protection of the law against such interference or attacks. 
				<Button variant='contained'>Something</Button>
				</p> */}
				{/* <img src={image} style={{'zIndex':1000,'position':'absolute'}} /> */}
		</div>
    )
}

export default Home;