import React, { useEffect, useRef, useState } from 'react'
import HTMLFlipBook from 'react-pageflip';
import frame2by2 from '../assets/frame2:2.png';
import frame2by3 from '../assets/frame2:3.png';
import frame3by2 from '../assets/frame3:2.png';
import bookCover2by2 from '../assets/bookCover2:2.png';
import bookCover2by3 from '../assets/bookCover2:3.png';
import bookCover3by2 from '../assets/bookCover3:2.png';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';



interface BookAttributes {
    bookAspectRatio: string;
    imageAspectRatio: string;
    pages: any;
    title: string;
    bookCover: string;
}

const BookComponent = ({ bookAspectRatio, imageAspectRatio, pages, title, bookCover }: BookAttributes) => {
    const [currentPage, setCurrentPage] = useState<number>(0);
    const bookRef = useRef<any>();
    const onFlip = (e: any) => {
        setCurrentPage(e.data);
        // currentPage = e.data;
        // if (e.data >= 1 && !(e.data > pages.length))
        //     setNewImagePrompt(pages[e.data - 1].replace(/[\n\r]/g, ' '));
        // else
        //     setTab(0);
        // return;
    }



    return (
        // The design for this input field was taken from https://github.com/mui/material-ui/issues/9716, codesandbox link: https://codesandbox.io/s/react-mui-file-input-cfsno?file=/src/FileInput.tsx:1617-1849 */ 
        <Grid item={true} xs={(bookAspectRatio === '2:3') ? 9 : 12} position={'relative'} container sx={{ 'margin': '15px 25px 0px' }} >

            <HTMLFlipBook
                autoSize={true}
                className={'test'}
                clickEventForward={true}
                drawShadow={true}
                disableFlipByClick={false}
                flippingTime={1000}
                height={(bookAspectRatio === '3:2') ? 495 :  (bookAspectRatio === '2:3') ? 680 : 495}
                // height={495}
                maxShadowOpacity={1}
                minHeight={250}
                minWidth={252}
                maxHeight={1000}
                maxWidth={1000}
                mobileScrollSupport={true}
                onFlip={onFlip}
                size={'stretch'}
                ref={bookRef}
                showCover={true}
                showPageCorners={true}
                startPage={0}
                startZIndex={0}
                style={{}}
                swipeDistance={30}
                usePortrait={true}
                useMouseEvents={true}
                // Width is a scale factor of 0.72 from height
                // width={688}
                width={(bookAspectRatio === '3:2') ? 680 : 495}
            >
                <div className='bookCover'>
                    {
                        bookCover !== '' ?
                            <img src={bookCover} className='genCover'/>
                        :
                            <p>{title}</p>
                    }
                    <img className='defaultCover' src={(bookAspectRatio === '3:2') ? bookCover3by2 : (bookAspectRatio === '2:3') ? bookCover2by3 : bookCover2by2} />
                </div>

                {pages.map((data: any, index: any) => {
                    if (index % 2 === 0) {
                        return (
                            <div className="leftPage" key={`page${index}`}>
                                <p>{data}</p>
                            </div>
                        )
                    }
                    else {
                        return (
                            <div className="rightPage" key={`page${index}`}>
                                <div className={(imageAspectRatio === '2:3') ? 'twobythree' : (imageAspectRatio === '2:2') ? 'twobytwo' : 'threebytwo'}>
                                    <img src={data} />
                                    {(bookAspectRatio == "2:2") && <img src={frame2by2} className='frame' />}
                                    {(bookAspectRatio == "2:3") && <img src={frame2by3} className='frame' />}
                                    {(bookAspectRatio == "3:2") && <img src={frame3by2} className='frame' />}
                                </div>
                            </div>
                        )
                    }
                })}
            </HTMLFlipBook>
            <Grid item={true} container xs={12} className='controls' justifyContent={'space-around'} sx={{ 'margin': '10px 0px 40px' }} >
                <Grid item={true} xs={2}>
                    <Button variant='contained' disabled={currentPage === 0} onClick={() => { bookRef.current.pageFlip().flipPrev() }}>Back</Button>
                </Grid>
                <Grid item={true} xs={2} >
                    <Button variant='contained' disabled={currentPage === pages.length - 1 || pages.length - 1 <= 0} onClick={() => { bookRef.current.pageFlip().flipNext() }}>Forward</Button>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default BookComponent;